import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
  FileErrorsEnum,
  IFileAttachmentResponse,
} from "../models/file-attachment.model";
import { ErrorService } from "./error.service";
import { HttpService } from "./http.service";
import { HttpHandler } from "./httpHandler";

@Injectable({
  providedIn: "root",
})
export class FileAttachmentService extends HttpHandler {
  private _files = new BehaviorSubject<File[]>([]);
  private _filesUploadErrors = new BehaviorSubject<string[]>([]);

  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService
  ) {
    super(_http, httpService, errorService);
  }

  addFile(file: File): void {
    const updatedFiles = [...this._files.value, file];
    this._files.next(updatedFiles);
  }

  removeFile(index: number): void {
    const updatedFiles = [...this._files.value];
    updatedFiles.splice(index, 1);
    this._files.next(updatedFiles);
  }

  getFiles(): Observable<File[]> {
    return this._files.asObservable();
  }

  clearFiles(): void {
    this._files.next([]);
  }

  addFilesUploadErrors(errors: string[]) {
    this._filesUploadErrors.next(errors);
  }

  getFilesUploadErrors(): Observable<string[]> {
    return this._filesUploadErrors.asObservable();
  }

  clearFilesUploadErrors(): void {
    this._filesUploadErrors.next([]);
  }

  sendFilesAttachment(parentId?: string, description?: string): Observable<IFileAttachmentResponse> {
    const url = "/salesforce-attachment";
    this.clearFilesUploadErrors();

    if (this._files.value.length) {
      const formData = this.generateFormData();
      if (parentId) {
        formData.append("parentId", parentId);
      }
      if (description) {
        formData.append("description", description);
      }
      return this.postFiles(
        url,
        formData
      ) as Observable<IFileAttachmentResponse>;
    } else {
      return of({
        success: true,
        message: "no attachments were added",
      }) as Observable<IFileAttachmentResponse>;
    }
  }

  validateFiles(
    incomingFiles: File[],
    allowedMimeTypes: string[],
    maxFileSizeAllowedInMB: number
  ): string {
    const mimeTypeError = this.validateFileMimeType(
      incomingFiles,
      allowedMimeTypes
    );
    const fileSizeError = this.validateFileSize(
      incomingFiles,
      maxFileSizeAllowedInMB
    );

    return mimeTypeError || fileSizeError;
  }

  private validateMaxNumOfFiles(
    incomingFiles: File[],
    maxNumOfFilesAllowed: number
  ): string {
    if (
      this._files.value.length + incomingFiles.length >
      maxNumOfFilesAllowed
    ) {
      return `${FileErrorsEnum.NUM_OF_FILES_ERROR} ${maxNumOfFilesAllowed}`;
    }
    return "";
  }

  private validateFileMimeType(
    incomingFiles: File[],
    allowedMimeTypes: string[]
  ): string {
    for (const file of incomingFiles) {
      const fileType = file.type || 'application/octet-stream';
      if (!allowedMimeTypes.includes(fileType)) {
        return `${file.name} ${FileErrorsEnum.MIME_TYPE_ERROR}`;
      }
    }
    return "";
  }

  private validateFileSize(
    incomingFiles: File[],
    maxFileSizeAllowedInMB: number
  ): string {
    const maxFileSizeInBytes = maxFileSizeAllowedInMB * 1024 * 1024;
    for (const file of incomingFiles) {
      if (file.size > maxFileSizeInBytes) {
        return `${file.name} ${FileErrorsEnum.FILE_SIZE_ERROR} ${maxFileSizeAllowedInMB}`;
      }
    }
    return "";
  }

  private generateFormData(): FormData {
    const formData: FormData = new FormData();
    for (const file of this._files.value) {
      formData.append("files[]", file, file.name);
    }
    return formData;
  }
}
