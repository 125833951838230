import { Component, OnInit } from "@angular/core";
import { ButtonComponent } from "../common/button/button.component";
import { ButtonDropdownComponent } from "../common/button-dropdown/button-dropdown.component";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { CommonModule } from "@angular/common";
import { UploadFileComponent } from "../modals/upload-file/upload-file.component";
import { ModalService } from "src/app/services/modal.service";
import { BreadcrumbsComponent } from "../common/breadcrumbs/breadcrumbs.component";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { UploadFilesComponent } from "../generic-components/upload-files/upload-files.component";
import { FileAttachmentService } from "src/app/services/file-attachment.service";
import { HeaderComponent } from "../common/header/header.component";
import * as moment from "moment";
import { GeneralPopupComponent } from "../common/general-popup/general-popup.component";
import { PopupTypes } from "src/app/models/popuptype";

@Component({
  selector: "app-employer-action",
  templateUrl: "./employer-action.component.html",
  styleUrls: ["./employer-action.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ButtonDropdownComponent,
    UploadFileComponent,
    BreadcrumbsComponent,
    UploadFilesComponent,
    HeaderComponent,
    GeneralPopupComponent,
  ],
})
export class EmployerActionComponent implements OnInit {
  public saveBtn = false;

  newAttachment = PopupTypes.newAttachment;
  modals = {};
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.EMPLOYER_ACTIONS,
  ];
  public clickAddFile: boolean = false;
  public mainButton: string;
  constructor(
    public dataService: DataService,
    public textService: TextService,
    private modalService: ModalService,
    private readonly fileAttachmentService: FileAttachmentService
  ) { }
  ngOnInit(): void {
    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
  }
  save() {
    this.saveBtn = true;
    this.dataService.showDataLoader = true;
    const description = `קובץ גביה חודש גביה ${moment(Date.now()).format("MM/YYYY")}`;
    this.fileAttachmentService.sendFilesAttachment(undefined, description).subscribe(
      (resp) => {
        if (resp.success) {
          this.dataService.showNewAttachment = true;
          this.fileAttachmentService.clearFiles();
        }
        else {
          this.saveBtn = false
        }
        this.dataService.showDataLoader = false;
      },
      (err) => {

      }
    );
  }
}
