export const environment = {
  production: false,
  name: "gur",
  logo: "logo-gur.svg",
  color: "yellow",
  colors: ["#157454", "#77bfaf", "#303746", "#ceb265", "e1ddd9", "#51699e"],
  analyticsKey: "UA-75644792-7",
  title_name: "Amnon Gur",
  url: "http://3.250.114.154/api/v1",
  // url: 'http://192.168.10.58:3000/api/v1',
  // url: "http://localhost:3000/api/v1",
  main_site: "http://3.250.114.154/login",
  support_mail: "it@gurg.co.il",
  auth_header_key: "employers-portal-a-g-App-Auth",
  auth_header_value: "dj9!%jhpxW1G)oj+3VdD",
};
